.container {
  margin-bottom: calc(var(--gap) * 2);
}

.title {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.item-collection {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
}

.label {
  font-weight: bold;
}


@media screen and (min-width: 900px) {
  .title {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .title>*:last-child {
    margin-left: auto;
  }
}