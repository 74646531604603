.container {
  display: flex;
  gap: .5rem;
  flex-wrap: wrap;
}

.container.align-left {
  justify-content: flex-start;
}

.container.align-center {
  justify-content: center;
}

.container.align-right {
  justify-content: flex-end;
}