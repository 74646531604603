.container {
  border-radius: 0;
  height: 100vh;
  height: 100dvh;
  display: grid;
  grid-template-rows: auto minMax(0, 1fr) auto;
  gap: var(--gap);
}

.container.medium-screen {
  grid-template-rows: auto minMax(0, 1fr);
}

.aside {
  height: 100%;
  overflow: auto;
  margin-left: var(--gap);
}

.content {
  display: grid;
  grid-template-columns: minMax(0, 1fr);
  gap: var(--gap);
}

.content.medium-screen {
  grid-template-columns: auto minMax(0, 1fr);
}

.page-content {
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: var(--gap);
  margin-left: var(--gap);
}

.page-content.medium-screen {
  margin-left: 0;
}

.page-container {
  padding: 0 !important;
}