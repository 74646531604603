.tag-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.project-button {
  margin-bottom: var(--gap);
}

.project-button.no-margin {
  margin-bottom: 0;
}