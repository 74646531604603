.tag span {
  width: 100%;
}

.container {
  display: flex;
}

.tag-count {
  text-align: right;
  margin-left: 1rem;
}