.container {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  min-width: 10rem;
  height: 100%;
}

.title {
  text-align: center;
}

.tags-container {
  /* max-height: 100%; */
  overflow: auto;
  display: grid;
  gap: 1rem;
}